import React from "react";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

// Register required Chart.js components
ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);
interface ChartProps {
  data: { [key: string]: number };
  title: string;
}

const Chart: React.FC<ChartProps>  = ({ data, title }) => {
  const barChartData = {
    labels: Object.keys(data),
    datasets: [
      {
        label: title,
        data: Object.values(data),
        backgroundColor: "rgba(75, 192, 192, 0.5)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        display: true,
        text: title,
      },
      legend: {
        display: true,
        position: "top" as const,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return <Bar data={barChartData} options={options} />;
};

export default Chart;
