import React, { useState } from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";

interface UploadModalProps {
  open: boolean;
  onClose: () => void;
}

const UploadModal: React.FC<UploadModalProps> = ({ open, onClose }) => {
  const [files, setFiles] = useState<{ [key: string]: File | null }>({
    claimsStandards: null,
    claimsFinancials: null,
    communicationLogs: null,
  });

  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    if (e.target.files?.[0]) {
      setFiles({ ...files, [type]: e.target.files[0] });
    }
  };

  const handleSubmit = () => {
    console.log(files); // Perform any desired action with the files
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" component="h2" sx={{ mb: 3 }}>
          Upload Data
        </Typography>

        <Box sx={{ mb: 2 }}>
          <Typography variant="body1" sx={{ mb: 1 }}>
            Claims standards and communication standards
          </Typography>
          <Button
            variant="outlined"
            component="label"
            startIcon={<AttachFileIcon />}
            fullWidth
          >
            Upload file
            <input
              hidden
              accept="application/pdf"
              type="file"
              onChange={(e) => handleFileChange(e, "claimsStandards")}
            />
          </Button>
          <Typography variant="caption">
            {files.claimsStandards
              ? files.claimsStandards.name
              : "PDF files only"}
          </Typography>
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="body1" sx={{ mb: 1 }}>
            Claims and financials
          </Typography>
          <Button
            variant="outlined"
            component="label"
            startIcon={<AttachFileIcon />}
            fullWidth
          >
            Upload file
            <input
              hidden
              accept=".csv, .xlsx"
              type="file"
              onChange={(e) => handleFileChange(e, "claimsFinancials")}
            />
          </Button>
          <Typography variant="caption">
            {files.claimsFinancials
              ? files.claimsFinancials.name
              : "CSV / Excel"}
          </Typography>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="body1" sx={{ mb: 1 }}>
            Communication logs
          </Typography>
          <Button
            variant="outlined"
            component="label"
            startIcon={<AttachFileIcon />}
            fullWidth
          >
            Upload file
            <input
              hidden
              accept=".csv, .json"
              type="file"
              onChange={(e) => handleFileChange(e, "communicationLogs")}
            />
          </Button>
          <Typography variant="caption">
            {files.communicationLogs
              ? files.communicationLogs.name
              : "CSV / JSON"}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
          <Button onClick={onClose} color="error">
            Cancel
          </Button>
          <Button onClick={handleSubmit} variant="contained">
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default UploadModal;
