import React, { useEffect, useState } from 'react'
import Navbar from '../../components/navbar/Navbar'
import homeStyles from './home.module.css';
import Header from '../../components/header/Header';
import ChartsOverviewDemo from '../../components/chart/Chart';
import CustomCard from '../../components/cards/CustomCard';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import CustomTable from '../../components/customTable/CustomTable';
import LineChart from '../../components/chart/LineChart';
import { Button, Typography } from '@mui/material';
import UploadIcon from "../../assets/images/Icon.svg";
import TickIcon from "../../assets/images/icon2.svg";
import CheckIcon from "../../assets/images/ci_check-all.svg";
import FileIcon from "../../assets/images/gala_file-error.svg";
import FileCrossIcon from "../../assets/images/fileiconcross.svg";
import UploadModal from '../../components/modal/UploadDataModal';
import { fetchComplianceOverview } from '../../services/complianceService';
import Grid2 from "@mui/material/Grid2";
import { useNavigate } from 'react-router-dom';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));
export default function Home() {
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  let navigate = useNavigate();

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    navigate('/claim-response')

  };
  useEffect(() => {
    const getComplianceData = async () => {
      try {
        setLoading(true);
        const result = await fetchComplianceOverview();
        setData(result);
      } catch (err: any) {
        setError(err.message || "Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    getComplianceData();
  }, []);
  return (<>
    {openModal && (
      <UploadModal open={openModal} onClose={handleCloseModal} />
    )}
    <div className={homeStyles.dashboard_container}>

      {/* <div className={homeStyles.nav_container}> */}
      <Navbar />
      {/* </div> */}

      <div className={homeStyles.main_content}>
        {/* Header Section */}
        <Header />
        <div className={homeStyles.header}>
          <Typography
            sx={{
              // fontFamily: 'Poppins',
              fontSize: '32px',
              fontWeight: '500',
              lineHeight: '48px',
              textAlign: 'left',
            }}>Overview</Typography>
          <Button sx={{
            backgroundColor: "#E8DEF8", padding: "16px 20px",
            minWidth: "80px", borderRadius: "16px", color: "#4A4459", gap: "5px",
          }}
            onClick={handleOpenModal}
          >
            <img src={UploadIcon} />Upload Data</Button>
        </div>
        <div className={homeStyles.above_section}>
          {/* Charts Section */}
          <div className={homeStyles.chart_section}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Paper sx={{ width: "100%", height: "380px" }}>
                  {data && (
                    <ChartsOverviewDemo
                      data={data.tpa_statistics || {}}
                      title="TPA Statistics"
                    />
                  )}
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper sx={{ width: "100%", height: "380px" }}>
                  {data && (
                    <ChartsOverviewDemo
                      data={data.adjuster_statistics || {}}
                      title="Adjuster Statistics"
                    />
                  )}
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper sx={{ width: "100%", height: "380px" }}>
                  {data && (
                    <ChartsOverviewDemo
                      data={data.risk_statistics || {}}
                      title="Risk Statistics"
                    />
                  )}
                </Paper>
              </Grid>
            </Grid>

          </div>
          {/* Cards Section */}
          <div className={homeStyles.cards_section}>
            <CustomCard name="Total Compliance Checks" value={data?.total_compliance_checks || 0} icon={TickIcon} />
            <CustomCard name="Overall Compliance Rate (%)" value={data?.overall_compliance_rate?.toFixed(2) || 0} icon={CheckIcon} />
            <CustomCard name="Non-Compliant" value={data?.total_non_compliant_count || 0} icon={FileIcon} />
            <CustomCard name="Compliant" value={data?.total_compliant_count || 0} icon={FileCrossIcon} />
          </div>
        </div>
        {/* Table Section */}
        <div className={homeStyles.table_section}>
          {
            data?.claims && <><Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "32px",
                fontWeight: "500",
                lineHeight: "48px",
                textAlign: "left",

              }} >
              Claims Summary
            </Typography>
              <CustomTable data={data.claims} />
            </>
          }

        </div>
      </div>

    </div >
  </>
  )
}
