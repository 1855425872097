import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./navbar.css"
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import DoneAllSharpIcon from "@mui/icons-material/DoneAllSharp";
import SettingsIcon from "@mui/icons-material/Settings";
import ReorderIcon from '@mui/icons-material/Reorder';
import FilterNoneIcon from "@mui/icons-material/FilterNone";
import StorageIcon from "@mui/icons-material/Storage";

interface NavItem {
  text: string;
  icon: React.ReactNode;
  route: string;
  disabled?: boolean;
}

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const items: NavItem[] = [
    {
      text: "",
      icon: <StorageIcon />,
      route: "/overview",
    },
    {
      text: "",
      icon: <FilterNoneIcon />,
      route: "/tpa",
    },

  ];

  const [activeRoute, setActiveRoute] = useState<string>(location.pathname);

  useEffect(() => {
    // Set the active route based on the current location
    setActiveRoute(location.pathname);
  }, [location.pathname]);

  const handleClick = (route: string) => {
    if (!items.find((item) => item.route === route)?.disabled) {
      setActiveRoute(route);
      navigate(route);
    }
  };

  return (
    <div className="side-nav">
      <div className="item-container">
        <div className="side-nav-header">
          <ReorderIcon sx={{ color: "black" }} />
        </div>

        {/* <Toolbar
          sx={{
            minHeight: { xs: "56px", sm: "40px" }, // Increase the min-height
          }}
        /> */}
        <Divider />
        <List>
          {items.map((item) => (

            <ListItem disablePadding key={item.text} >
              <ListItemButton
                onClick={() => handleClick(item.route)}
                // disabled={item.disabled}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",  // Centers the icon and text horizontally
                  justifyContent: "center", // Centers the content vertically
                  textAlign: "center",
                  backgroundColor: activeRoute === item.route ? "#E8DEF8" : "transparent",
                  borderRadius: activeRoute === item.route ? "20px" : "0px",
                  "&.Mui-selected": {
                    backgroundColor: "#E8DEF8", // Active background
                  },

                }}
              // className={
              //   activeRoute === item.route ? "active-item" : "list-item"
              // }
              >
                <ListItemIcon
                  sx={{
                    color: activeRoute === item.route ? "#000000" : "#625B71",
                    alignContent: "center",
                  }}

                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.text}
                  primaryTypographyProps={{
                    sx: {
                      color: activeRoute === item.route ? "#625B71" : "#49454F",
                      fontSize: "0.875rem",
                      textAlign: "center",
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>


          ))}
        </List>
      </div>
    </div>
  );
};

export default Navbar;
