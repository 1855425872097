// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Gilroy-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/Gilroy-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/Gilroy-Light.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Gilroy-Bold';
  src: local('Gilroy-Bold'), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: local('Gilroy-Medium'), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
}

@font-face {
  font-family: 'Gilroy-Light';
  src: local('Gilroy-Light'), url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
}
/* src/fonts/fonts.css */
@font-face {
  font-family: 'Poppins';
  src: local('./fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  src: local('./fonts/Poppins/Poppins-Bold.ttf') format('truetype'); /* Add other weights if necessary */
  font-weight: 400; /* Regular */
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('./fonts/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700; /* Bold */
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(222, 216, 225, 1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,qFAA0E;AAC5E;;AAEA;EACE,4BAA4B;EAC5B,uFAA8E;AAChF;;AAEA;EACE,2BAA2B;EAC3B,sFAA4E;AAC9E;AACA,wBAAwB;AACxB;EACE,sBAAsB;EACtB,oEAAoE;EACpE,iEAAiE,EAAE,mCAAmC;EACtG,gBAAgB,EAAE,YAAY;EAC9B,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;EACtB,iEAAiE;EACjE,gBAAgB,EAAE,SAAS;EAC3B,kBAAkB;AACpB;;AAEA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,wCAAwC;AAC1C;;AAEA;EACE;aACW;AACb","sourcesContent":["@font-face {\r\n  font-family: 'Gilroy-Bold';\r\n  src: local('Gilroy-Bold'), url(./fonts/Gilroy-Bold.ttf) format('truetype');\r\n}\r\n\r\n@font-face {\r\n  font-family: 'Gilroy-Medium';\r\n  src: local('Gilroy-Medium'), url(./fonts/Gilroy-Medium.ttf) format('truetype');\r\n}\r\n\r\n@font-face {\r\n  font-family: 'Gilroy-Light';\r\n  src: local('Gilroy-Light'), url(./fonts/Gilroy-Light.ttf) format('truetype');\r\n}\r\n/* src/fonts/fonts.css */\r\n@font-face {\r\n  font-family: 'Poppins';\r\n  src: local('./fonts/Poppins/Poppins-Regular.ttf') format('truetype');\r\n  src: local('./fonts/Poppins/Poppins-Bold.ttf') format('truetype'); /* Add other weights if necessary */\r\n  font-weight: 400; /* Regular */\r\n  font-style: normal;\r\n}\r\n\r\n@font-face {\r\n  font-family: 'Poppins';\r\n  src: local('./fonts/Poppins/Poppins-Bold.ttf') format('truetype');\r\n  font-weight: 700; /* Bold */\r\n  font-style: normal;\r\n}\r\n\r\nbody {\r\n  margin: 0;\r\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\r\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\r\n    sans-serif;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n  background-color: rgba(222, 216, 225, 1);\r\n}\r\n\r\ncode {\r\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\r\n    monospace;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
