import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/login/Login';
import Home from './pages/home/Home';
import DetailsPage from './pages/details/DetailsPage';
import ClaimResponse from './pages/claimresponse/ClaimResponse';
import ClaimsDetailsPage from './pages/claimresponse/ClaimResponseMain';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/overview" replace />} />
        <Route path="/login" element={<Login />} />
        <Route path="/overview" element={<Home />}  />
        <Route path="/tpa/:claimId" element={<DetailsPage />} />
        <Route path="/claim-response" element={<ClaimsDetailsPage />} />
        
      </Routes>
    </Router>
  );
}

export default App;
