import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';

interface CardProps {
  value: string | number;  // value could be either a string or number
  name: string;
  icon?: any;
}

const CustomCard: React.FC<CardProps> = ({ value, name, icon }) => {
  return (
    <Card
      sx={{
        maxWidth: 445,
        boxShadow: 3,
        borderRadius: '12px',
        position: 'relative',
        '::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '8px', // Thickness of the line
          backgroundColor: '#4F378A', // Adjust color
          borderRadius: '12px 0 0 12px', // Match the card's rounded corner
        }
      }}
    >
      <CardContent sx={{ alignContent: "center", textAlign: "center" }}>
        <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
          {icon && <img src={icon} />}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: "column", alignItems: 'center' }}>
          <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
            {value}
          </Typography>
          <Typography component="div" sx={{  fontSize: "14px",color:"#1E1E1E", }}>
            {name}
          </Typography>

        </Box>
      </CardContent>
    </Card>

  );
}

export default CustomCard;
