import React, { useEffect, useState } from 'react'
import Navbar from '../../components/navbar/Navbar'
import homeStyles from './DetailsPage.module.css';
import Header from '../../components/header/Header';
import ChartsOverviewDemo from '../../components/chart/Chart';
import CustomCard from '../../components/cards/CustomCard';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';

import CustomTable from '../../components/customTable/CustomTable';
import LineChart from '../../components/chart/LineChart';
import { Button, Typography } from '@mui/material';
import UploadIcon from "../../assets/images/Icon.svg";
import TickIcon from "../../assets/images/icon2.svg";
import CheckIcon from "../../assets/images/ci_check-all.svg";
import FileIcon from "../../assets/images/gala_file-error.svg";
import FileCrossIcon from "../../assets/images/fileiconcross.svg";
import { useParams } from 'react-router-dom';
import { fetchClaimDetails } from '../../services/complianceService';
import DetailTable from '../../components/table/DetailTable';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

export default function DetailsPage() {
  const { claimId } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getComplianceData = async () => {

      try {
        setLoading(true);
        const result = await fetchClaimDetails(claimId || "");
        setData(result);
      } catch (err: any) {
        setError(err.message || "Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    getComplianceData();
  }, [claimId]);
  return (
    <div className={homeStyles.dashboard_container}>
      {/* <div className={homeStyles.nav_container}> */}
      <Navbar />
      {/* </div> */}

      <div className={homeStyles.main_content}>
        {/* Header Section */}
        <Header />
        <div className={homeStyles.header}>
          <Typography
            sx={{
              // fontFamily: 'Poppins',
              fontSize: '32px',
              fontWeight: '500',
              lineHeight: '48px',
              textAlign: 'left',
            }}>Claim Details</Typography>

          <div className={homeStyles.heading}>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "18px",
                fontWeight: "500",
                textAlign: "left",
                padding: "16px",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                color: "#1E1E1E"
              }}
            >
              <span>Claim ID -</span>
              <Typography
                component="span"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#1E1E1E"
                }}
              >
                {claimId}
              </Typography>
            </Typography>
          </div>

          {/* Additional header content */}
        </div>
        <div className={homeStyles.cardsContainer}>
          <CustomCard name="Total Compliance Checks" value={data?.total_compliance_checks || 0} icon={TickIcon} />
          <CustomCard name="Overall Compliance Rate (%)" value={data?.compliance_rate?.toFixed(2) || 0} icon={TickIcon} />
          <CustomCard name="Non-Compliant" value={data?.total_non_compliant_count || 0} icon={FileIcon} />
          <CustomCard name="Compliant" value={data?.total_compliant_count || 0} icon={CheckIcon} />
        </div>

        {/* Table Section */}
        <div className={homeStyles.table_section}>
          {
            data && <>
              <DetailTable data={data.compliance_checks} /></>
          }

        </div>
      </div>

    </div >
  )
}
