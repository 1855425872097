import React from 'react'
import styles from './header.module.css'
import { Typography } from '@mui/material'
import logo from "../../assets/images/Group.svg"
import avatar from "../../assets/images/avatar.svg"

export default function Header() {
    return (
        <div className={styles.header_container}>
            <div className={styles.title_container}>
                <img src={logo} alt='Logo' />
                <Typography sx={{
                    fontFamily: "Gilroy-Bold",
                    fontSize: "24px",
                    fontWeight: '400',
                    lineHeight: '33.6px',
                    textAlign: 'center',
                    color: '#4F378A',
                    // text-decoration: underline;
                    // text-underline-position: from-font;
                    // text-decoration-skip-ink: none;
                }}>AI Claims Audit</Typography>

            </div>
            <div>
            <img src={avatar} alt='Logo' />
            </div>

        </div >
    )
}

