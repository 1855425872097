// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-nav {
    background-color:rgba(254, 247, 255, 1);
    min-height: 200vh;
    height: auto;
    width:100px;
    /* border-radius: 18px; */
    transition: height 0.3s ease-in-out;
}
.item-container {
    padding: 14px;
}
.side-nav-header {
    font-size: 24px;
    font-weight: 800;
    color: #FFFFFF;
    line-height: 38.4px;
    font-family: 'Montserrat'  !important;
}
.side-nav-header-sub {
    font-size: 14px;
    font-weight: 800;
    color: #FFFFFF;
    font-family: 'Montserrat'  !important;
    line-height: 24px;
}

.list-item {
    background-color: transparent;
    font-family: 'Poppins' !important;
    color: #FFFFFF; /* Non-active text remains white */
}

.active-item {
   display: flex;
   flex-direction: column;
   text-align: center;
   align-items: center;
    background-color:#E8DEF8; /* White background for the active item */
    color: #488f6e; /* Green color for text when active */
    font-family: 'Poppins' !important;
    border-radius: 20px; /* Optional: Add some rounding to match your style */
}

.active-item .MuiListItemIcon-root {
    color:rgb(0, 0, 0); /* Green icon color when active */
}



  `, "",{"version":3,"sources":["webpack://./src/components/navbar/navbar.css"],"names":[],"mappings":"AAAA;IACI,uCAAuC;IACvC,iBAAiB;IACjB,YAAY;IACZ,WAAW;IACX,yBAAyB;IACzB,mCAAmC;AACvC;AACA;IACI,aAAa;AACjB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,mBAAmB;IACnB,qCAAqC;AACzC;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,qCAAqC;IACrC,iBAAiB;AACrB;;AAEA;IACI,6BAA6B;IAC7B,iCAAiC;IACjC,cAAc,EAAE,kCAAkC;AACtD;;AAEA;GACG,aAAa;GACb,sBAAsB;GACtB,kBAAkB;GAClB,mBAAmB;IAClB,wBAAwB,EAAE,yCAAyC;IACnE,cAAc,EAAE,qCAAqC;IACrD,iCAAiC;IACjC,mBAAmB,EAAE,oDAAoD;AAC7E;;AAEA;IACI,kBAAkB,EAAE,iCAAiC;AACzD","sourcesContent":[".side-nav {\r\n    background-color:rgba(254, 247, 255, 1);\r\n    min-height: 200vh;\r\n    height: auto;\r\n    width:100px;\r\n    /* border-radius: 18px; */\r\n    transition: height 0.3s ease-in-out;\r\n}\r\n.item-container {\r\n    padding: 14px;\r\n}\r\n.side-nav-header {\r\n    font-size: 24px;\r\n    font-weight: 800;\r\n    color: #FFFFFF;\r\n    line-height: 38.4px;\r\n    font-family: 'Montserrat'  !important;\r\n}\r\n.side-nav-header-sub {\r\n    font-size: 14px;\r\n    font-weight: 800;\r\n    color: #FFFFFF;\r\n    font-family: 'Montserrat'  !important;\r\n    line-height: 24px;\r\n}\r\n\r\n.list-item {\r\n    background-color: transparent;\r\n    font-family: 'Poppins' !important;\r\n    color: #FFFFFF; /* Non-active text remains white */\r\n}\r\n\r\n.active-item {\r\n   display: flex;\r\n   flex-direction: column;\r\n   text-align: center;\r\n   align-items: center;\r\n    background-color:#E8DEF8; /* White background for the active item */\r\n    color: #488f6e; /* Green color for text when active */\r\n    font-family: 'Poppins' !important;\r\n    border-radius: 20px; /* Optional: Add some rounding to match your style */\r\n}\r\n\r\n.active-item .MuiListItemIcon-root {\r\n    color:rgb(0, 0, 0); /* Green icon color when active */\r\n}\r\n\r\n\r\n\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
