import React, { useEffect, useState } from 'react'
import Navbar from '../../components/navbar/Navbar'
import homeStyles from './ClaimResponse.module.css';
import Header from '../../components/header/Header';
import ChartsOverviewDemo from '../../components/chart/Chart';
import CustomCard from '../../components/cards/CustomCard';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import CustomTable from '../../components/customTable/CustomTable';
import LineChart from '../../components/chart/LineChart';
import { Button, Typography } from '@mui/material';
import UploadIcon from "../../assets/images/Icon.svg";
import TickIcon from "../../assets/images/icon2.svg";
import CheckIcon from "../../assets/images/ci_check-all.svg";
import FileIcon from "../../assets/images/gala_file-error.svg";
import FileCrossIcon from "../../assets/images/fileiconcross.svg";
import UploadModal from '../../components/modal/UploadDataModal';
import { fetchComplianceOverview } from '../../services/complianceService';
import Grid2 from "@mui/material/Grid2";
import { useNavigate } from 'react-router-dom';
import ClaimResponse from './ClaimResponse';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    // textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
        backgroundColor: '#1A2027',
    }),
}));
export default function Home() {
    const [openModal, setOpenModal] = useState(false);
    const [data, setData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    let navigate = useNavigate();

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => {
        setOpenModal(false);
        navigate('/claim-response')

    };
    // useEffect(() => {
    //     const getComplianceData = async () => {
    //         try {
    //             setLoading(true);
    //             const result = await fetchComplianceOverview();
    //             setData(result);
    //         } catch (err: any) {
    //             setError(err.message || "Failed to fetch data");
    //         } finally {
    //             setLoading(false);
    //         }
    //     };

    //     getComplianceData();
    // }, []);
    return (<>
        {openModal && (
            <UploadModal open={openModal} onClose={handleCloseModal} />
        )}
        <div className={homeStyles.dashboard_container}>

            {/* <div className={homeStyles.nav_container}> */}
            <Navbar />
            {/* </div> */}

            <div className={homeStyles.main_content}>
                {/* Header Section */}
                <Header />
                <div className={homeStyles.header}>
                    <Typography
                        sx={{
                            // fontFamily: 'Poppins',
                            fontSize: '32px',
                            fontWeight: '500',
                            lineHeight: '48px',
                            textAlign: 'left',
                        }}>Multi-Agent Auditing</Typography>

                </div>

                {/* Table Section */}
                <div className={homeStyles.table_section}>
                    <ClaimResponse />

                </div>
            </div>

        </div >
    </>
    )
}
