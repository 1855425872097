import api from "./apiService";

export const fetchComplianceOverview = async () => {
  try {
    const response = await api.get("/compliance-overview");
    return response.data;
  } catch (error: any) {
    console.error("Error fetching compliance overview:", error);
    throw error;
  }
};

export const fetchClaimDetails = async (claimId:string) => {
  try {
    const response = await api.get(`/claim-details?claim_id=${claimId}`);
    return response.data;
  } catch (error: any) {
    console.error("Error fetching compliance overview:", error);
    throw error;
  }
};