// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_header_container__Feiqb{
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    /* width: Fixed (1, 839px)px; */
    /* height: Hug (76px)px; */
    /* left: 81px; */
    padding: 20px 20px 20px 20px;
    gap: 0px;
    justify-content: space-between;
    opacity: var(--sds-size-stroke-border);
   

}
.header_title_container__3RiRr{
    /* width: -webkit-fill-available; */
    display: flex;
    flex-direction: row;
    gap:5px;
    font-size: 20px;
    color: var(--sds-color-text-default);
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/header/header.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,+BAA+B;IAC/B,0BAA0B;IAC1B,gBAAgB;IAChB,4BAA4B;IAC5B,QAAQ;IACR,8BAA8B;IAC9B,sCAAsC;;;AAG1C;AACA;IACI,mCAAmC;IACnC,aAAa;IACb,mBAAmB;IACnB,OAAO;IACP,eAAe;IACf,oCAAoC;IACpC,iBAAiB;AACrB","sourcesContent":[".header_container{\r\n    display: flex;\r\n    flex-direction: row;\r\n    text-align: center;\r\n    align-items: center;\r\n    /* width: Fixed (1, 839px)px; */\r\n    /* height: Hug (76px)px; */\r\n    /* left: 81px; */\r\n    padding: 20px 20px 20px 20px;\r\n    gap: 0px;\r\n    justify-content: space-between;\r\n    opacity: var(--sds-size-stroke-border);\r\n   \r\n\r\n}\r\n.title_container{\r\n    /* width: -webkit-fill-available; */\r\n    display: flex;\r\n    flex-direction: row;\r\n    gap:5px;\r\n    font-size: 20px;\r\n    color: var(--sds-color-text-default);\r\n    font-weight: bold;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header_container": `header_header_container__Feiqb`,
	"title_container": `header_title_container__3RiRr`
};
export default ___CSS_LOADER_EXPORT___;
