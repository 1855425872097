import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styles from './ClaimResponse.module.css';

interface IResponseObject {
    role: string;
    content: string;
}

export default function ClaimResponse() {
    const params = useParams();
    const [responseList, setResponseList] = useState<IResponseObject[]>([]);

    useEffect(() => {
        const eventSource = new EventSource("https://api.aiaudit.techvantagesystems.com/start-audit?num_claims=44&force_redo=True");

        eventSource.onmessage = (event) => {
            console.log("Received event from SSE connection:", event);
            const responseObject = JSON.parse(event.data);
            setResponseList((prev: IResponseObject[]) => [
                ...prev,
                {
                    role: responseObject.role || "",
                    content: responseObject.content || "",
                },
            ]);
        };

        eventSource.onerror = (error) => {
            console.log("Error with SSE connection:", error);
            eventSource.close();
        };

        return () => {
            eventSource.close();
        };
    }, []);

    // Function to handle line breaks and special characters
    const formatContent = (content: string) => {
        // Replace '\n' with <br /> to create new lines in the text
        return content.replace(/\n/g, '<br />');
    };

    return (
        <div className={styles.responseContainer}>
            {responseList.length > 0 ? (
                responseList.map((response, index) => (
                    <div key={index} className={styles.responseBox}>
                        <p className={styles.responseRole}>{response.role}</p>
                        <pre
                            className={styles.responseContent}
                            dangerouslySetInnerHTML={{
                                __html: formatContent(response.content),
                            }}
                        />
                    </div>
                ))
            ) : (
                <p>Loading Model response...</p>
            )}
        </div>
    );
}
