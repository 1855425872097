import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "rgb(232 222 248)",
        color: "black",
        fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function createData(
    name: string,
    calories: number,
    fat: number,
    carbs: number,
    protein: number,
) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function DetailTable(props: any) {
    
    return (
        <TableContainer component={Paper} >
            <Table sx={{ minWidth: 700, }} aria-label="customized table">
                <TableHead sx={{ backgroundColor: "rgb(232 222 248)" }}>
                    <TableRow>
                        <StyledTableCell>Clause Number</StyledTableCell>
                        <StyledTableCell align="right">Clause Text</StyledTableCell>
                        <StyledTableCell align="right">Compliant</StyledTableCell>
                        <StyledTableCell align="right">Explanation</StyledTableCell>
                        <StyledTableCell align="right">Action Items</StyledTableCell>
                        {/* <StyledTableCell align="right">Auditor Approval </StyledTableCell> */}
                        {/* <StyledTableCell align="right">Auditor Feedback </StyledTableCell> */}

                    </TableRow>
                </TableHead>
                <TableBody>
                    {props?.data?.map((row:any) => (
                        <StyledTableRow key={row.name}>

                            <StyledTableCell align="right">{row.clause_number}</StyledTableCell>
                            <StyledTableCell align="right">{row.clause}</StyledTableCell>
                            <StyledTableCell align="right">{row.compliant? "Yes": "No"}</StyledTableCell>
                            <StyledTableCell align="right">{row.reason}</StyledTableCell>
                            <StyledTableCell align="right">{row.action_items}</StyledTableCell>
                            {/* <StyledTableCell align="right">{row.fat}</StyledTableCell> */}
                            {/* <StyledTableCell align="right">{row.fat}</StyledTableCell> */}


                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
