import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(232 222 248)",
    color: "black",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  cursor:"pointer",
}));

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number,
) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function CustomTable(props: any) {
  let navigate = useNavigate();
  const handleRowClick = (claimId:string) => {
    navigate(`/tpa/${claimId}`)
  }
  return (
    <TableContainer component={Paper} >
      <Table sx={{ minWidth: 700, }} aria-label="customized table">
        <TableHead sx={{backgroundColor:"rgb(232 222 248)"}}>
          <TableRow>
            <StyledTableCell>Claim ID</StyledTableCell>
            <StyledTableCell align="right">Risk Level </StyledTableCell>
            <StyledTableCell align="right">Report Date</StyledTableCell>
            <StyledTableCell align="right">Loss Date</StyledTableCell>
            <StyledTableCell align="right">Coverage</StyledTableCell>
            <StyledTableCell align="right">Total Incurred</StyledTableCell>
            {/* <StyledTableCell align="right">Total Reserved</StyledTableCell> */}
            {/* <StyledTableCell align="right">Total Paid</StyledTableCell> */}
            <StyledTableCell align="right">Adjuster</StyledTableCell>
            {/* <StyledTableCell align="right">Compliance Checks</StyledTableCell> */}
            {/* <StyledTableCell align="right">Compliant</StyledTableCell> */}
            <StyledTableCell align="right">Compliance
              Rate (%)</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data?.map((row:any) => (
            <StyledTableRow key={row.name} onClick={()=>handleRowClick(row.OCCURRENCE_NUMBER_TEXT)}>
              <StyledTableCell component="th" scope="row">
                {row.OCCURRENCE_NUMBER_TEXT}
              </StyledTableCell>
              <StyledTableCell align="right">{row.risk_level}</StyledTableCell>
              <StyledTableCell align="right">{row.REPORT_DATE}</StyledTableCell>
              <StyledTableCell align="right">{row.LOSS_DATE}</StyledTableCell>
              <StyledTableCell align="right">{row.COVERAGE}</StyledTableCell>
              <StyledTableCell align="right">{row.TOTAL_INCURRED_NET}</StyledTableCell>
              {/* <StyledTableCell align="right">{row.TOTAL_PAID_NET}</StyledTableCell> */}
              {/* <StyledTableCell align="right">{row.TOTAL_PAID_NET}</StyledTableCell> */}
              <StyledTableCell align="right">{row.adjuster_name}</StyledTableCell>
              {/* <StyledTableCell align="right">{row.total_compliance_checks}</StyledTableCell> */}
              {/* <StyledTableCell align="right">{row.compliant}</StyledTableCell> */}
              <StyledTableCell align="right">{row.compliance_rate?.toFixed(2)}</StyledTableCell>

            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
